import { Dialog, Toast } from "vant";
import Api from "@/api/caseHandle";
import ApiUser from "@/api/user";

export default {
  components: {},
  data() {
    return {
      userBaseInfo: {
        resOperatorDTO: {},
      },

      actionType: "",
      actionItem: {},
      showPopup: false,
      popupForm: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 弹出层打开
    async openPopup() {
      this.showPopup = true;
    },

    // 弹出层确认
    confirmPopup(form) {
      this.popupForm = { ...form };
      let { actionType, actionItem } = this;
      // this.showPopup = false;
      if ("退单" == actionType) {
        if (!form.valueReason) {
          Toast.fail("请输入退单原因");
          return;
        }
        this.returnClick(actionItem);
      }
      if ("接单" == actionType) {
        if (!form.valueDate) {
          Toast.fail("请选择家访时间");
          return;
        }
        this.acceptClick(actionItem);
      }
      if ("派单" == actionType) {
        if (!form.valuePerson || !form.valuePersonId) {
          Toast.fail("请选择家访人员");
          return;
        }
        this.distributeClick(actionItem);
      }
      if ("改派" == actionType) {
        if (!form.valuePerson || !form.valuePersonId) {
          Toast.fail("请选择家访人员");
          return;
        }
        this.changeClick(actionItem);
      }
      if ("改期" == actionType) {
        if (!form.valueDate) {
          Toast.fail("请选择家访时间");
          return;
        }
        this.changeTimeClick(actionItem);
      }
      if ("中止" == actionType) {
        if (!form.suspendType.length) return Toast.fail("请选择中止类型");
        if (!form.suspendReason) return Toast.fail("请选择");
        if (form.suspendReason == "其他" && !form.reason) {
          return Toast.fail("输入原因");
        }
        this.changeSuspend();
      }
    },

    // 按钮事件
    onActionClick(action, item) {
      this.actionType = action;
      this.actionItem = { ...item };
      this.openPopup(item);
    },

    // 退单
    async returnClick(item) {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认退单",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.reject({
          homeNo: item.homeNo,
          reason: popupForm.valueReason,
        });

        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 接单
    async acceptClick(item) {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认接单",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.accept({
          homeNo: item.homeNo,
          dateStr: popupForm.valueDate,
        });

        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 派单
    async distributeClick(item) {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认派单",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.assignment({
          homeNo: item.homeNo,
          operatorId: popupForm.valuePersonId,
        });

        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 改派
    async changeClick(item) {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认改派",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.change({
          homeNo: item.homeNo,
          operatorId: popupForm.valuePersonId,
        });

        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 改期
    async changeTimeClick(item) {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认改期",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        const res = await Api.changeTime({
          homeNo: item.homeNo,
          dateStr: popupForm.valueDate,
        });

        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        Toast.fail(error);
      }
    },
    // 中止
    async changeSuspend() {
      let { popupForm } = this;
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认中止",
        })) !== "confirm"
      ) {
        return;
      }
      Toast.loading();
      try {
        let { resOperatorDTO = {} } = JSON.parse(
          localStorage.getItem("userAccount") || "{}"
        );
        let { name = "-", mobile = "-" } = resOperatorDTO;
        const res = await Api.suspensionOfHomeVisits({
          createBy: `${name}-${mobile}`,
          suspendType: this.popupForm.suspendType.join(","),
          projectCode: this.actionItem.applyBizNo,
          suspendSource: "HOME",
          suspendReason:
            popupForm.suspendReason == "其他"
              ? popupForm.reason
              : popupForm.suspendReason,
        });
        Toast.success(res.message);
        this.onRefresh();
        this.showPopup = false;
      } catch (error) {
        console.log("error :>> ", error);
        Toast.fail(error);
      }
    },
  },
  async mounted() {
    const userBaseInfo = await ApiUser.baseInfo();
    userBaseInfo.success && (this.userBaseInfo = userBaseInfo.data);
  },
};
